import React from "react"

import "./benefit.scss"

const Benefit = ({ benefit: { url, _key, title, description } }) => {
  const urlFilter = url => {
    url = url.substr(-1) === "/" ? url.slice(0, -1) : url
    url = url.replace("https://", "")
    url = url.replace("http://", "")
    return url
  }

  return (
    <a
      href={url}
      className="benefit"
      key={_key}
      target="_blank"
      rel="noreferrer"
    >
      <span className="benefit__title">{title},</span>
      <span className="benefit__url">{urlFilter(url)}</span>
      <span className="benefit__description">{description}</span>
    </a>
  )
}

export default Benefit
